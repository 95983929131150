<script>
  import GitHub from "../../assets/svgs/github.svg";
  export let width = "20px";
  export let height = width;
</script>

<div class="github-svg-container">
  <GitHub width="{width}" height="{height}" />
</div>

<style lang="scss">
  .github-svg-container {
    width: 20px;
    height: 20px;
  }
</style>
