<script>
  import Google from "../../assets/svgs/google.svg";
  export let width = "20px";
  export let height = width;
</script>

<div class="google-svg-container">
  <Google width="{width}" height="{height}" />
</div>

<style lang="scss">
  .google-svg-container {
    width: 20px;
    height: 20px;
  }
</style>
